<template>
  <div class="video-you-tube">
    <div class="video-you-tube__wrapper">
      <iframe :allow="params.allow"
              :height="params.height"
              :src="`https://www.youtube.com/embed/${params.src}`"
              :width="params.width"
              allowfullscreen/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoYouTube',
  props: {
    params: {
      type: Object,
      required: true,
      allow: {
        type: Object,
        default: () => ({
          allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
        }),
      },
    },
  },
};
</script>
